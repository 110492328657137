@import 'variables';
@import 'addons';

* {
    // Helper class that sets opacity of an element
    @each $o in $opacity {
        &.opacity-#{$o} {
            opacity: calc(#{$o} / 100);
        }
    }
}

@font-face {
    font-family: eve-font;
    src: url('./assets/fonts/evesansneue-regular.otf');
}

@font-face {
    font-family: eve-font-bold;
    src: url('./assets/fonts/evesansneue-bold.otf');
}

pre {
    background-color: transparent;
    color: inherit;
    margin: 0;
    padding: 0;
    white-space: pre;
    word-wrap: normal;
}

// Custom styling
html {

    //background-color: $eve_ore_content;

    body {

        .no-text-overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        table.table * {
            border-color: $text-muted;
        }

        hr {
            border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        // Set some standard rendering as the defaults might vary between browsers and operating systems
        //font-family: "open-sans", sans-serif;
        //font-family: "eve-font", sans-serif;
        //font-size: $standard-font-size;
        //line-height: $standard-line-height;
        //color: $eve_text_color;
        //margin: 0;

        //padding: ($navbar_top_height + $body_padding) $body_padding $body_padding ($navbar_side_width + $body_padding) !important;
        padding: ($navbar-top-height + $body-padding) $body-padding $body-padding ($navbar-side-width + $body-padding) !important;

        .error-div,
        footer {
            text-align: center;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            margin: 0;

            &.spacing {
                margin-bottom: 10px;
                margin-top: 10px;
            }

            &.spacing-top {
                margin-top: 1em;
            }

            &.spacing-bottom {
                margin-bottom: 1em;
            }
        }

        li.nav-item {
            cursor: pointer;
        }

        .underline-info {
            border-bottom: 1px dotted;
            cursor: help;
        }

        a {
            //color: $eve_skill_training;
            cursor: pointer;
            text-decoration: none;
            transition: color 0.2s ease-in-out;

            &.underline {
                position: relative;

                &::before {
                    background-color: $link-color;
                    bottom: 0;
                    content: '';
                    height: 1px;
                    left: 0;
                    position: absolute;
                    transform: scaleX(0);
                    transition: all 0.2s ease-in-out;
                    visibility: hidden;
                    width: 100%;
                }
            }

            &:focus {
                color: $link-color;
                text-decoration: none;

                &.underline {
                    &::before {
                        transform: scaleX(1);
                        visibility: visible;
                    }
                }
            }

            &:hover {
                color: white !important;
                text-decoration: none;

                &.underline {
                    &::before {
                        transform: scaleX(1);
                        visibility: visible;
                    }
                }
            }

            &.eve-link {
                color: $eve-text-yellow;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        *:disabled,
        .disabled {
            pointer-events: none !important;
        }

        //.visible-lg, .visible-md, .visible-sm, .visible-xs {
        //  display: none !important;
        //}

        //table {
        //  width: 100%;
        //  border-spacing: 0;
        //  thead {
        //    th {
        //      border-left: $eve_ore_button_active solid 1px;
        //      border-top: $eve_ore_button_active solid 1px;
        //      border-bottom: $eve_ore_button_active solid 1px;
        //      padding-left: 5px;
        //      padding-right: 5px;
        //      &:last-child {
        //        border-right: $eve_ore_button_active solid 1px;
        //      }
        //    }
        //  }
        //  tbody {
        //    tr {
        //      transition: background-color 0.1s linear;
        //      td {
        //        border-left: $eve_ore_button_active solid 1px;
        //        padding-left: 5px;
        //        padding-right: 5px;
        //        &:last-child {
        //          border-right: $eve_ore_button_active solid 1px;
        //        }
        //      }
        //      &:hover {
        //        //background-color: darken($eve_ore_button_active, 9%);
        //        background-color: darken($eve_ore_button_active, 20%);
        //      }
        //      &:last-child {
        //        td {
        //          border-bottom: $eve_ore_button_active solid 1px;
        //        }
        //      }
        //    }
        //  }
        //}

        //.left {
        //  text-align: left;
        //}
        //
        //.center {
        //  text-align: center;
        //}
        //
        //.right {
        //  text-align: right;
        //}

        //#characterInfoWindow {
        //  display: none; /* Hidden by default */
        //  position: fixed; /* Stay in place */
        //  z-index: 1; /* Sit on top */
        //  left: 0;
        //  top: 0;
        //  width: 100%; /* Full width */
        //  height: 100%; /* Full height */
        //  overflow: auto; /* Enable scroll if needed */
        //  background-color: rgb(0, 0, 0); /* Fallback color */
        //  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        //  .window-title {
        //
        //  }
        //  .window-content {
        //    //text-align: center;
        //    box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.75);
        //    background-color: $eve_ore_content;
        //    margin: 15% auto; /* 15% from the top and centered */
        //    padding: 10px;
        //    border: 1px solid #888;
        //    width: 40%; /* Could be more or less, depending on screen size */
        //    height: auto;
        //    .window-title {
        //      #close-button {
        //        float: right;
        //      }
        //    }
        //    #character-image {
        //      float: left;
        //      img {
        //        width: 128px;
        //      }
        //    }
        //    #character-info {
        //      float: left;
        //      width: 75%;
        //      padding-left: 10px;
        //      #corporation {
        //        #corporation-image {
        //          float: left;
        //        }
        //        #corporation-info {
        //          float: left;
        //          width: 75%;
        //          padding-left: 10px;
        //        }
        //      }
        //      #alliance {
        //        #alliance-image {
        //          float: left;
        //        }
        //        #alliance-info {
        //          float: left;
        //          width: 75%;
        //          padding-left: 10px;
        //        }
        //      }
        //    }
        //  }
        //}
        //
        //#entry-body {
        //  #register-section, #login-section {
        //    width: 50%;
        //    float: left;
        //    padding: 10px;
        //  }
        //  #temporary-section {
        //    padding: 10px;
        //  }
        //}

        //form {
        //  .form-group {
        //    margin-bottom: 15px;
        //    label {
        //      display: inline-block;
        //      max-width: 100%;
        //      margin-bottom: 5px;
        //      font-weight: 700;
        //    }
        //    input {
        //      display: block;
        //      width: 100%;
        //      height: 34px;
        //      padding: 6px 12px;
        //      font-size: 14px;
        //      line-height: 1.42857143;
        //      color: #555;
        //      background: #fff none;
        //      border: 1px solid #ccc;
        //      &:focus {
        //        outline: none;
        //      }
        //    }
        //  }
        //}

        button {
            cursor: pointer;
        }

        //button.accordion {
        //  background-color: transparent;
        //  border: 0;
        //  color: $eve-text-color;
        //  cursor: pointer;
        //  font-size: $standard-font-size;
        //  font-weight: bold;
        //  line-height: $standard-line-height;
        //  outline: none;
        //  text-align: left;
        //  transition: 0.4s;
        //
        //  i {
        //    text-align: left;
        //    width: 25px;
        //  }
        //
        //  &::after {
        //    color: #777777;
        //    content: '\002B';
        //    float: right;
        //    font-weight: bold;
        //    margin-left: 5px;
        //  }
        //
        //  &.active {
        //    &::after {
        //      content: '\2212';
        //    }
        //
        //    i::before {
        //      content: '\f07c';
        //    }
        //  }
        //}
        //
        //div.accordion-body {
        //  max-height: 0;
        //  overflow: hidden;
        //  padding: 0 18px;
        //  transition: max-height 0.3s ease-in-out;
        //}

        .tooltip {
            @each $direction in 'top', 'right', 'left', 'bottom' {
                &.bs-tooltip-#{$direction} .arrow::before {
                    border-#{$direction}-color: $primary;
                }
            }

            .tooltip-inner {
                background-color: $primary;
            }
        }
    }
}
