$eve-gold: #96732b;
$eve-white: #cfcfce;

$eve-gold-omega: #ffcc00;

$eve-ore-button-active: #958d21;
$eve-ore-button-highlight: #6e691e;
$eve-ore-button: #43431a;

$eve-ore-body: #0b1216;
$eve-ore-content: #151d23;

$eve-skill-untrained: #444a4f;
$eve-skill-trained: #d0d2d3;
$eve-skill-training: #2f849e;

$eve-text-color-bold: #ffffff;
$eve-text-color: #c2c4c5;
$eve-text-yellow: #ffff00;

$eve-sec-10: #2fefef;
$eve-sec-09: #48F0C0;
$eve-sec-08: #00EF47;
$eve-sec-07: #00F000;
$eve-sec-06: #8FEF2F;
$eve-sec-05: #EFEF00;
$eve-sec-04: #D77700;
$eve-sec-03: #F06000;
$eve-sec-02: #F04800;
$eve-sec-01: #D73000;
$eve-sec-00: #F00000;

$body-primary: #101010;
$body-secondary: #171B23;

$navbar-top-height: 46px;
$navbar-side-width: 50px;
$body-padding: 20px;

$opacity: 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

$standard-font-size: 1rem;
$standard-line-height: 1.42857143;

// Overrides
$warning: $eve-text-yellow;
$body-bg: $body-primary;
$body-color: $eve-text-color;

$theme-colors: (
    "ore": #6E4423,
    "ice": #6B838D,
    "gas": #9f658e,
);

@import '../node_modules/bootswatch/dist/superhero/variables';
